import { FaqAccordion } from "@/components/FaqAccordion";
import SectionItem from "@/components/SectionItem";
import { Skeleton, Stack, rem } from "@mantine/core";
import { useTranslation } from "next-i18next";

export default function FAQ(): JSX.Element {
  const { t, ready } = useTranslation();

  /**
   * Refer from faq.json locale file for the items displayed on top.
   */
  const items = [
    t("faq:contents.1.contents.0", {
      returnObjects: true,
    }),
    t("faq:contents.1.contents.1", {
      returnObjects: true,
    }),
    t("faq:contents.0.contents.2", {
      returnObjects: true,
    }),
    t("faq:contents.0.contents.3", {
      returnObjects: true,
    }),
    t("faq:contents.4.contents.2", {
      returnObjects: true,
    }),
    t("faq:contents.0.contents.4", {
      returnObjects: true,
    }),
  ];

  return (
    <SectionItem
      pb={rem(10)}
      title={t("top:sections.faq.title")}
      titleUrlLabel={t("top:sections.faq.link")}
      titleUrl="/faq"
    >
      <Stack>
        {ready ? (
          <FaqAccordion item={items} />
        ) : (
          <>
            {[...Array(6)].map((_, index) => (
              <Skeleton key={index} h={rem(48)} w="100%" />
            ))}
          </>
        )}
      </Stack>
    </SectionItem>
  );
}
